//0606bb12 light blue input
//0659bbf0 button
//000000c8 lbls form
//00000017 border color
//0000009f hint lbl

//variables
@import 'toggleSwitch';
@import 'pdf-template';
$input-background: #0606bb12;
$submit-button: #0659bbf0;
$form-label-color: #000000c8;
$grey-one: #00000017;
$grey-two: #0000009f;
$grey-three: #f5f5f5;

//form
.form-grp {
	width: 500px;
	min-height: 300px;
	border: 1px solid $grey-one;
	margin: auto;
	margin-top: 40px;
	border-radius: 5px;
}
.fm-main-field {
	display: flex;
	width: 440px;
	margin: auto;
	margin-top: 30px;
}
.fm-part-group {
	display: block;
	width: 220px;
}
.fieldEl {
	width: 200px;
	background-color: $input-background;
	height: auto;
	margin: 5px;
	padding: 5px 10px;
	border-radius: 5px;
	font-size: 0.9em;
	overflow: hidden;
	input,
	select {
		width: 180px;
		height: 30px;
		border: 1px solid $grey-one;
		padding: 5px 10px;
		border-radius: 5px;
		font-size: 1em;
		color: #333;
		font-weight: 500;
		&:focus {
			outline: none;
		}
	}
}
.splitEl {
	display: flex;

	input {
		width: 100%;
	}
	.comp {
		margin-right: 5px;
	}
}
.submitBtn {
	height: 40px;
	width: 200px;
	background-color: $submit-button;
	color: #fff;
	border: 0;
	border-radius: 5px;
	font-weight: 500;
	z-index:100;
	cursor: pointer;
	margin: 5px;
	&:focus {
		outline: none;
	}
}
.hint-label {
	color: $grey-two;
	font-size: 0.8em;
}
.yuOp2 {
	.submitBtn {
		position: fixed;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		margin: auto;
		bottom: 20px;
		right: 20px;
		box-shadow: 1px 2px #ddd;
	}
}
.hint-btn {
	border-radius: 50%;
	width: 25px;
	margin: 0 10px 0 0;
	height: 25px;
	font-weight: 500;
	font-size: 1.5em;
	border: 1px solid $grey-one;
}

// DASHBOARD STYLE
.pane {
	display: flex;
	
}
.curr {
	background-color: $input-background;
}
.sb-main1 {
	position:fixed;
	background: #fff;
}
.sidebar-container {
	width: 180px !important;
	height: 100vh;
	border-right: 1px solid $grey-one;
	box-shadow: 1px 2px 12px #ccc;
	li {
		height: 40px;
		width: 100%;
		&:hover {
			background-color: lighten($input-background, 50%);
		}
		.link-itm {
			padding: 10px;
			display: flex;
			vertical-align: middle;
			background-color: #fff;
			img {
				margin-right: 10px;
				box-shadow: 1px 2px 3px #ddd;
				padding: 3px;
				border-radius: 3px;
			}
		}
		a {
			text-decoration: none;
			color: #333;
			font-weight: 480;
		}
	}
}
.main-bost-pg {
	width: 100%;
	padding: 10px;
	margin-left:180px;
}
.tbl-wrap2{
	background-color: #fff;
    position: fixed;
    overflow: scroll;
	height: calc(100vh - 150px);
	z-index: -3;
	margin-top:10px;
}
.bost-tbl {
	min-width: 70vw;
	text-align: left;
	th {
		font-weight: 500;
		height:30px;
		background-image:linear-gradient(#222B47,#222B47);
		color:#fff;
		padding:0 10px;
	}
	td {
		padding: 5px;
	}
	tr:nth-child(even) {
		background-color: $grey-three;
	}
}
.nav-back {
	height: 2.5em !important;
	width: 2.5em;
	//background: $grey-three;
	border-radius: 50%;
	color: #333;
	margin: 10px;
}
.err-container {
	width: 500px;
	margin: auto;
}
.err-box {
	color: red;
	text-align: left;
}
.sticky-btm-row{
	position: -webkit-sticky;
	position:sticky;
	bottom:0;
	background-color:#fff;
	text-align:left;
}
.sticky{
	position: -webkit-sticky;
	position:sticky;
	top:0;
	
	text-align:center;
}

.TjkG3 {
	background-color: #fff;
	min-height: 100vh;
}
.btm-tbody {
}

.coll_order_date {
	display: flex;
	justify-content: space-around;
	align-content: center;
	.div1 {
		text-align: right;
		width: 60%;
		padding-top: 10px;
		padding-right: 30px;
	}
		.div2 {
		text-align: right;
		width: 40%;
		padding-top: 10px;
		padding-right: 5px;
	}
}
.sdUtd3 {
	display: flex;
	justify-content: stretch;
	span {
		width: 180px;
	}
}
.yuYio0 {
	display: flex;
	justify-content: space-around;
}
.filter-wrap1{
	background-color:#fff;
	position: relative;
	height: 80px;
	
}
.filter_form {
	background-color:#fff;
	margin-bottom: 30px;
	position: fixed;
	.fieldEl {
		width: 150px;
		padding-top: 0;
		label {
			font-size: 0.8em;
		}
		input,
		select {
			width: 130px;
			font-size: 1em;
		}
	}
}
.filter_btn_5 {
	input {
		width: 80px !important;
	}
	.fieldEl {
		background: inherit;
		vertical-align: middle !important;
		width: 80px;
		padding: 0;
		input {
			margin-top: 30px;
		}
	}
}

//error nhandling
.msg-err {
	min-height: 50px;

	padding: 10px;
	width: 280px;
	position: fixed;
	right: 10px;
	border-radius: 10px;
	background-color: red;
	color: #fff;
	z-index: 100;

	div {
		font-size: 0.8em;
	}
	button {
		font-weight: 500;
		color: #fff;
		border: 0;
		font-size: 1.5em;
		position: absolute;
		right: 10px;
		background-color: inherit;
		margin-top: -10px;
	}
}
.btn-logout {
	border: 0;
	background-color: inherit;
	text-align: left;
	&:focus {
		outline: none;
	}
}
.wrapForPrint {
	width: 800px;
	min-height: 100vh;
	background: #fff;
	margin: auto;
	padding-bottom: 100px;
}
.fdoDnY7 {
	height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	//align-items:center;
}
.dashboard-preview {
	display: flex;
	width: 100%;
	height: auto;

	margin: 50px;
	display: flex;

	justify-content: center;
	div {
		cursor: pointer;
		a {
			color: rgb(241, 237, 237);
			text-decoration: none;
		}
		width: 350px;
		height: 250px;

		box-shadow: rgb(28 39 43 / 20%) 0px 5px 8px;
		border-radius: 20px;
		margin: 10px 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		h1 {
			background-color: rgba(255, 255, 255, 0.3);
			height: 150px;
			width: 250px;
			backdrop-filter: blur(40px);
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 10px;
			border: 1px solid transparent;
			background-clip: padding-box;
			//background-color: #fff;
		}
	}
	.UIbT1 {
		color: #fff;
		background-image: linear-gradient(rgba(royalblue, 0.8), indigo);
	}
	.UIbT2 {
		background-image: linear-gradient(rgba(tomato, 0.8), rgba(purple, 0.4));
	}
}
.login-fm {
	min-height: 200px;
	width: 215px;
}

.prompt {
	left: 0;
	height: 100vh;
	width: 100vw;
	background-color: rgba(44, 43, 43, 0.8);
	position: fixed;
	z-index: 1000;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	.confirm {
		height: 100px;
		width: 250px;
		border-radius: 10px;
		background: #fff;
		text-align: center;
	}
	.btns2 {
		position: absolute;
	}
	p {
		position: absolute;
		text-align: center;
		width: 250px;
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	button {
		width: calc(250px / 2);
		height: 30px;
		border: 1px solid #ddd;
		margin-top: 70px;
	}
	.pos {
		color: green;
		border-bottom-left-radius: 10px;
	}
	.neg {
		color: red;
		border-bottom-right-radius: 10px;
	}
	.confirm {
		width: 250px !important;
	
	}
}
.delete-waybill-btn {
	color: red;
	float: right;
	border: 1px solid red;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	position: fixed;
	bottom: 30px;
	right: 90px;
	font-weight: 500;
	font-size: 2em;
	cursor: pointer;
}
.to-excel-btn {
	float: right;
	height: 40px;
	width: auto;
	background-color: #ddd;
	border: 0;
	a {
		color: #333;
		text-decoration: none;
	}
	img {
		height: 30px;
	}
}
//bdc-omc form styles
.hjOpfT2 {
	.confirm {
		margin: auto;
		height: 400px;
		button {
			margin-top: 0px;
		}
	}
	.fm-main-field {
		width: auto;
	}
	.fm-part-group {
		margin: auto;
	}
}
.typEg1 {
	height: 30px;
	border: 0;
	background-color: transparent;
	padding: 5px 10px;
	color: mdnightblue;
}
.close3 {
	font-weight: 500;
	font-size: 2em;
	color: red;
	position: fixed;
	background-color: transparent;
	border: 0 !important;
	right: 100px;
	margin-top: -100px !important;
	z-index: 100;
}
.delete-row-btn {
	width: 40px;
	border: darken(red, 30%);
	background-image: linear-gradient(red, darken(red, 30%));
	height: 40px;
	color: #fff;
	border-radius: 50%;
	margin-right: 20px;
	position: fixed;
	font-weight: 500;
	font-size: 2em;
	right: 70px;
	z-index:100;
	bottom: 20px;
	cursor: pointer;
}
.gh6Ty {
	background-color: $grey-one;
}
.erTf {
	padding: 10px;
	height: 250px;
	overflow-y: scroll;
	overflow-x: hidden;

	.bost-tbl {
		min-width: 230px !important;
		tr,
		td {
			width: 230px;
		}
	}
}
//anime
@keyframes slidein {
	from {
		margin-bottom: 100%;
		width: 300%;
	}

	to {
		margin-bottom: 0%;
		width: 100%;
	}
}
