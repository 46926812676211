.pdf-template {
  font-family: italic;
  width: 800px;
  min-height: 600px;
  box-sizing: border-box;
  background-color: #fff;
  padding: 40px;
  margin: auto;
  margin-top: 2%;

  /*
		background-image:url("../../images/pdf-format.jpeg");
		background-size:100%;
		background-repeat:no-repeat;*/
}
.pdf-headers {
  width: 90%;
  min-height: 160px;
  margin: auto;
  margin-bottom: 30px;
  margin-top: 15%;
  .pdf-headers-chd {
    display: flex;
    height: 80px;
   
    div {
    }
  }
}
.header-fan-brv {
  width: 40%;
  text-align: right;
  padding: 10px;
  padding-right: 10px;
  div {
    padding: 8px 10px;
  }
}
.hdr-bdc-omc {
  width: 60%;
  text-align: center !important;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;
  span {
    width: 100%;
    text-align: center !important;
  }
}
.pdf-tbl {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  th {
    padding: 10px;
  }
  td {
    padding: 5px;
  }
}
.pdf-td {
  width: calc(100% / 5);
  text-align: center;
  padding: 5px 10px;
}
.pos-right {
  text-align: right;
  padding: 10px;
  div {
    padding: 10px;
  }
}
.top-tbody {
  height: auto;
  width: 100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.destination-box {
  padding: 10px;
  text-align: center;
  // margin-top: 80px;
 margin-bottom:50px;
  width: 100%;
 // height: 100px;
}
.issuer-name {
  font-size: 1em !important;
  div {
    font-size: 0.8em !important;
    padding-top: 40px;
    vertical-align: bottom;
    margin-left: -20px;
  }
}
tbody {
  th,
  td {
    white-space: nowrap;
    overflow: hidden;
  }
}
